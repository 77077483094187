@import "app/assets/scss/variables";

.sWraper {
  select {
    min-height: 38px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 4px;
  }
 
}
