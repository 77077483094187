.list-ads {
  width: 100%;
  max-height: 180px;
}

.slick-prev,
.slick-next {
  display: none !important;
}

.slick-dots {
  bottom: -20px;
}
