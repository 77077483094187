@import 'app/assets/scss/_variables.scss';

.buttonIcon {
  padding: 12px;
  cursor: pointer;
}

.icon {
  font-size: 20px;
}

.header {
  color: #505050;
  padding-right: 5px;
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 800;
  line-height: 20px;
}

.content {
  font-size: 36px;
  font-weight: 700;
  line-height: 52px;
}

.listItemImage {
  cursor: pointer;
  transform-style: preserve-3d;
  border-radius: 50%;
  transition: all 0.2s;
  width: 150px;
  height: 150px;
}

.itemGenre {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  border-radius: 5px;
}
.itemGenres {
  padding: 6px 10px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  border-radius: 5px;
}
.itemsTag {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  border-radius: 5px;
}
.itemsTags {
  font-weight: 700;
  padding: 6px 10px;
  text-align: center;
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  border-radius: 5px;
}
@media screen and (max-width: 500px) {
  .iconEyeInside {
    color: #000 !important;
  }
}

@media screen and (max-width: 679px) {
  .iconEye {
    right: unset !important;
    left: 63px;
    top: 21px !important;
  }
}
