@import 'app/assets/scss/_variables.scss';

.inputSearchContainer {
  input {
    border-radius: 5px !important;
    min-height: 38px;
    min-width: 100px;
  }

  input::placeholder {
    color: #616161 !important;
  }
}
