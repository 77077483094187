// Colors
$MainDark: #000000;
$DarkWaveform: #ffffff00;
$ColorWaveformRegion: #21d4fd40;
$PreViewPhoto: #ededed;
$MainWhite: #ffffff;
$DisabledBG: #f3f3f3;
$SilverChalice: rgba(174, 174, 174, 0.25);
$BackgroundGray: #1c1c1c;
$BlackOpacity: rgba(black, 0.7);
$WhitePink: #fafafa;
$WhiteGray: #eef5ff;
$WhiteBg: #e1e1e1;
$DarkGray: #c5c5c5;
$BgOder: #999999;
$BlueLight: #31ba7e;
$LavenderPinocchio: #e0e0e0;
$Orange: #ed7358;
$Purple: #2a4365;
$Gray: #808080;
$DarkLight: #383838;
$GrayMedium: #838383;
$GrayLight: #afafaf;
$GrayLighter: #888585;
$GrayLightest: #e5e5e5;
$GrayDark: #656565;
$GrayBlack: #323232;
$BgGray: #434343;
$LightOrange: #ffd78a;
$DarkBlack: #242424;
$SilverChaliceAF: #afafaf;
$BgGrayText: #7d7d7d;
$SilverChalice: rgba(174, 174, 174, 0.25);
$BackgroundCommunity: #242424;
$BlackMomentum: #201e1e;
$WhiteLight: #aaa;
$LightGray: #939393;
$BlueLightGray: #dceaff;
$BlueText: #0055d6;
$OrangeDark: #a7a7a7;
$ColorWhite: #efefef;
$ColorBlue: #2971dd;
$YellowishGreen: #5b7157;
$ColorVideo: #00e0ff;
$ColorTextCountry: #d4d4d4;
$ColorBgProgress: #758eb4;
$ColorBgProgressLoading: #2e2d2d;

$Green: green;
$OrangeLighter: #b4990a;

$Red: red;
$RedLighter: #ca2929;
$GrayText: #808080;
$OrangeColor: #ed7358;
$MainBlue: #004377;
$FlatBlue: #4267b2;
$BgBanner: #5176ad;
$BorderBox: rgba(0, 0, 0, 0.1);
$BorderBox4: rgba(0, 0, 0, 0.4);
$BorderBoxSearch: rgba(0, 0, 0, 0.4);
$BorderColor: #d0d0d0;
$LightBlue: #007ff3;
$BackgroundCommunity: #242424;
$SelectedTop: #5c94e8;
$White: white;
$Black: #000;
$GrayWhite: #dbdbdb;
$BlueText: #0055d6;
$DarkGray: #7a7a7a;
$GrayColor: #939393;
$GreenLight: #7aa0da;
$WhiteLight: #ececec;
$BgGrayColor: #f9f9f9;
$ColorTextGray: #b8b8b8;
$Yellow: #fed901;
$YellowLight: #fffb00;
$Gray70: #b3b3b3;
$Gray34: #575757;
$RedDark: #920001;

$BorderBox: rgba(0, 0, 0, 0.1);
$BackgroundCreation: rgb(228, 209, 185);
$BorderBox4: rgba(0, 0, 0, 0.4);
$BackgroundPinkWhite: #fffaf9;
$BackgroundPink: #fff2ef;
$BackgroundHover: #f1f1f1;
$WhiteLighter: #f2f2f2;
$GrayLighter: rgba(255, 255, 255, 0.8);
$BgDownload: #e8e8e8;
$GrayLightest: #d9d9d9;
$ColorGray: #6d6d6d;
$colorTextInput: rgb(36, 36, 36);
$colorBgInput: rgba(255, 255, 255, 0.16);

// Breakpoints
$MaxDesktopLargeXx: 2435px !default;
$MaxDesktopLarge: 1680px !default;
$MaxDesktop: 1440px !default;
$LargeDesktop: 1350px !default;
$Desktop: 1024px !default;
$LargeTablet: 800px !default;
$Tablet: 600px !default;
$Mobile: 480px !default;
$MediumMobile: 375px !default;
$SmallMobile: 320px !default;

$FontSize10: 10px;
$FontSize12: 12px;
$FontSize14: 14px;
$FontSize15: 15px;
$FontSize16: 16px;
$FontSize18: 18px;
$FontSize40: 40px;
