@import 'app/assets/scss/_variables.scss';
@import 'app/assets/scss/responsive.scss';

.informationBanner {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #000;
  padding: 9px 25px;
  border: 1px solid #505050;
  border-bottom: none;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.informationBanner:hover {
  background-color: #fff;
  color: #000 !important;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
}

.informationBannerHover {
  background-color: #fff;
  color: #000;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
}

.bannerImageLabel {
  max-height: 100%;
  padding: 5px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.listLabelsBanner {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;
  padding-bottom: 5px;
  
  display: flex;
}

.listItem {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.innerBannerLabel {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.containerBannerLabel {
  background-image: url('../../assets/banners/official-label.png');
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 5px;
  padding-top: 12.5%;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  padding-bottom: 0;
  padding-right: 0;
  position: relative;
  overflow: hidden;
}

@media screen  and (max-width:620px) {
  .containerBannerLabel { 
    background-image: none;
    padding-top: 100px;
  }
  .listLabelsBanner {
    grid-column-gap: 8px;
  }
}

.listItemImage {
  opacity: 0.5;
  cursor: pointer;
  transform-style: preserve-3d;
  border-radius: 50%;
  width: 100%;
  transition: all 0.2s;
}

.listItemImage:hover {
  opacity: 1;
  transform: scale3d(1.2, 1.2, 0.1);
}

@media only screen and (max-width: 960px) {
  .logo {
    opacity: 0.5;
  }
}

@media only screen and (max-width: 768px) {
  .informationBanner {
    border-bottom: 1px solid #505050;
  }
}

@media only screen and (max-width: 500px) {
  .informationBanner {
    width: 100%;
  }
}
