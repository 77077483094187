@import 'app/assets/scss/variables';
.changePassword {
  background-color: $WhitePink;
}
.changePassworddark {
  background-color: var(--chakra-colors-gray-700);
}

.changePasswordTitle {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: $SelectedTop;
  text-align: center;
}
