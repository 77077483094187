@import 'app/assets/scss/variables';

.general {
  overflow: auto;
  height: 100vh;
  width: 100vw;
}

.sidebarComponents {
  position: relative;
  margin: auto;
}

input:focus {
  box-shadow: none !important;
}

.ctnPenChat {
  position: absolute;
  bottom: 80px;
  right: 20px;
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 57px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;

  .penChat {
    display: inline-block;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}