@import 'app/assets/scss/_variables.scss';

.container {
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  left: 0px;
  padding: 10px;
  color: $GrayLighter;
  border-radius: 4px 4px 0 0;
  width: 550px;
}

@media screen and (max-width: 767px) {
  .container {
    padding-bottom: 120px;
  }
}

.containerVideoPlayer {
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  right: 0px;
  width: 300px;
  bottom: 82px;
}

.containerFooter {
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  background-color: #524a4a;
  padding: 10px 18px;
  animation-name: containerFooter;
  animation-duration: 0.2s;
  height: 82px;
  width: 100%;

  .ml1 {
    gap: 25px;
    align-items: center;
  }

  .rangeVolume {
    cursor: pointer;
  }
  .progressBarContent {
    flex: 1;
    overflow: hidden;
    margin: 0 0.4em;
    .audioMain {
      align-items: center;
      width: 100%;
      display: inline-flex;
      justify-content: center;
      margin-top: 6px;
      .progressBar {
        flex: 1 1 auto;
        width: 100%;
        position: relative;
      }
    }
  }
  .trackSong{
    max-width: 400px;
  }
}

.container {
  width: 100%;
  max-width: 550px;
  bottom: 82px;
}

@media only screen and (max-width: 1200px) {
  .containerFooter {
    .trackSong{
      max-width: 300px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .containerFooter {
    .progressBarContent{
      margin: 0;
    }
    .left {
      width: calc(100% - 240px);
      overflow: hidden;
      img {
        display: none;
      }
      .mbDisplayNone {
        display: none;
      }
      .title {
        margin-left: 0;
        flex-grow: 1;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
    .right {
      justify-content: flex-end;
      .ml1 {
        margin-left: 0.5em;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .containerFooter {
    .progressBarContent{
      display: none;
    }
    .ml1 {
      gap: 10px;
      margin: 0px auto;
    }
    .rangeVolume{
      width: 100px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .containerFooter {
    .trackSong{
      max-width: 150px;
    }
    .rangeVolume{
      width: 80px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .containerFooter {
    .right {
      .mbDisplayNone {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .containerFooter {
    height: 60px;
  }
  .container {
    width: 100%;
    bottom: 60px;
  }
  .textSongName {
    max-width: 140px;
  }
  .ml1 {
    gap: 10px !important;
  }
}
@keyframes containerFooter {
  from {
    height: 0;
  }
  to {
    height: 60px;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.textSong {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.textSongName {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: $MainWhite;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    color: rgb(79, 123, 212);
  }
  cursor: pointer;
}
.textSongArtist {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $DarkGray;
}

#range-volume {
  background: $OrangeColor;
  border: solid 1px $OrangeColor;
  border-radius: 8px;
  height: 4px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 10px;
  background: $MainWhite;
  border-radius: 50%;
}
