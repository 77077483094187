@import 'app/assets/scss/_variables.scss';

.editProfile {
  padding-bottom: 100px;
  input:focus,
  textarea:focus {
    border-color: $BorderBox4;
    box-shadow: none;
  }
  .inputProfile {
    background: $MainWhite;
    color: $SilverChaliceAF;
  }

  .inputProfileDisabled {
    background: $DisabledBG;
    color: $Gray;
    border: 0.5px solid $GrayLight;
  }
  .slTimeFrame {
    width: 32%;
    box-sizing: border-box;
    border-radius: 5px;
    height: 0px !important;
  }
  .itemInput {
    display: flex;
    flex-direction: column;
    border-color: $GrayLight;
    select {
      padding: 0 10px;
    }

    select {
      width: 100%;
      height: 40px;
      border: 1px solid $GrayLight;
      border-color: $GrayLight;

      border-radius: 5px;
    }
  }
  .itemInputSelect {
    span {
      color: $Red;
    }
  }
  .itemInputdark {
    select {
      background-color: var(--chakra-colors-gray-700);
    }
  }
  .buttonResetWarning {
    padding: 10px;
  }
  .buttonProfile {
    background: $Orange;
    height: 36px;
    width: 117px;
    border-radius: 2px;
    color: $MainWhite;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .itemInputCheckbox {
    margin-top: 10px;
  }
  .mt15 {
    margin-top: 15px;
  }
  .css-g1d714-ValueContainer {
    padding: 0 5px;
  }
  .css-b8ldur-Input {
    margin: 0;
    padding: 0;
  }
  .mb5 {
    margin-bottom: 5px;
  }
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
.editProfiledark {
  input:focus,
  textarea:focus {
    border-color: var(--chakra-colors-whiteAlpha-600);
    box-shadow: none;
  }
}

.bold {
  font-weight: 600;
}
