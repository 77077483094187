.tabItemService {
  cursor: pointer;
  font-size: 25px;
  font-weight: 900;
}

// .tabItemServiceActive {
//   color: #37ff00;
//   background-color: #000;
//   box-shadow: 0 2px 5px 0 #000;
//   padding: 20px;
//   border-radius: 20px;
// }

.tabItemServiceActive {
  color: #ffffff;
  background-color: #000;
  // box-shadow: 0 2px 5px 0 #000;
  padding: 5px 10px;
  border-radius: 0px;
  font-size: 25px;
  font-weight: 900;
  line-height: 35px;
}
