@import 'app/assets/scss/variables';

.react-select {
  border: none !important;
  border-radius: 4px;
  .css-yk16xz-control {
    border: none !important;
  }
}

.react-select-light {
  color: $MainDark;
  > div,
  div:hover {
    box-shadow: none;
  }
}
.css-1wa3eu0-placeholder {
  font-size: 14px;
}
.css-1uccc91-singleValue {
  width: 100%;
  font-size: 14px;
}
.react-select-dark:focus,
.react-select-light:focus {
  border: none;
}
.react-select-light,
.react-select-dark {
  font-size: 14px;
  width: 100%;
  .css-1wa3eu0-placeholder {
    opacity: 0.4;
  }
  .css-1okebmr-indicatorSeparator {
    background-color: transparent;
  }
  .css-g1d714-ValueContainer {
    height: 36px;
    width: 100px;
  }
  .css-yk16xz-control .select__control {
    border: none !important;
  }
  .css-26l3qy-menu {
    color: $MainDark !important;
    z-index: 10;
  }
}

.react-select-light {
  .select__multi-value__label {
    color: $BackgroundCommunity;
  }

  .css-1rhbuit-multiValue {
    overflow-y: auto;
  }
  .css-g1d714-ValueContainer:hover {
    overflow-y: auto;
  }

  .css-14el2xx-placeholder {
    color: $colorTextInput !important;
  }

  > div,
  div:hover {
    border-color: inherit;
    box-shadow: none;
  }
}
.react-select-dark {
  .css-1s2u09g-control {
    background-color: transparent;
    border-color: $colorBgInput;
  }
  .css-tlfecz-indicatorContainer {
    color: $colorBgInput;
  }
  .select_select__vLst3__control {
    background-color: transparent;
    border-color: $colorBgInput;
  }
  .select_select__vLst3__indicator {
    color: $colorBgInput;
  }
  .css-14el2xx-placeholder {
    color: $colorBgInput;
  }

  .css-1pahdxg-control {
    background-color: $colorBgInput;
  }
  .css-qc6sy-singleValue {
    color: unset;
  }

  .css-26l3qy-menu {
    color: $SelectedTop !important;
    background-color: $MainDark;
  }
}
